module.exports = new Promise(resolve => {
            const remoteUrlWithVersion = 'https://cdn-go.woa.com/taiji-frontend/taiji-common/latest/remoteEntry.js?v=1728891911065'
            const script = document.createElement('script')
            script.src = remoteUrlWithVersion
            let dom = document.createElement('div')
            let url = window.location.origin + '/overview'
            dom.innerHTML = 'cdn挂载失败<a href="' + url + '" target="_blank">跳转首页</a>'
            dom.style = 'position:absolute; top:20px;'
            script.onerror = () => {
              // document.body.appendChild(dom)
              resolve({})
            }
            script.onload = () => {
              const proxy = {
                get: (request) => window.taiji_common.get(request),
                init: (arg) => {
                  try {
                    return window.taiji_common.init(arg)
                  } catch(e) {
                    console.log('remote container already initialized')
                  }
                }
              }
              resolve(proxy)
            }
            document.head.appendChild(script)
          });